<template>
  <v-navigation-drawer
    color="white"
    data-test="nav-drawer"
    expand-on-hover
    permanent
    rail
    :width="width"
  >
    <v-list class="pt-0" density="compact" nav>
      <app-nav-item v-for="item in visibleItems" :key="item.label" v-bind="item" />
    </v-list>

    <template v-slot:append>
      <v-list class="pb-8" density="compact" nav>
        <app-nav-item icon="mdi-account-circle" icon-color="interactive" label="My Account" :to="{ name: 'profile' }" />
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import AppNavItem from '@/components/app-nav-item.vue'

const REFRESH_INTERVAL = 2 * 60 * 1000 // 2 minutes

const accountingFailuresCount = ref(0)
const invoiceInboxCount = ref(0)
const showInvoicesItem = ref(false)
const width = ref(224)

const authStore = useAuthStore()
const canManageCompany = authStore.canManageCompany
const canManageInvoices = authStore.canManageInvoices
const canManageUsers = authStore.canManageUsers
const canUseExpenses = authStore.canUseExpenses
const canUseRequisitions = authStore.canUseRequisitions
const inSetupMode = authStore.inSetupMode
const isStaff = authStore.isStaff
const userCompany = authStore.userCompany
const user = authStore.user

const showAccountingFailureLink = computed(() => isStaff || (!!userCompany?.accounting_integration && canManageCompany))
const items = computed(() => [
  {
    label: 'Home',
    to: { name: 'dashboard' },
    icon: 'mdi-home-outline',
    show: true,
    dataTest: 'nav-link-home',
  },
  {
    label: 'Orders',
    to: { name: 'orders' },
    icon: 'mdi-clipboard-outline',
    show: !inSetupMode,
    dataTest: 'nav-link-orders',
  },
  {
    label: 'Expenses',
    to: { name: 'expenses' },
    icon: 'mdi-currency-usd',
    show: !inSetupMode && canUseExpenses,
    dataTest: 'nax-link-expenses',
  },
  {
    label: 'Invoices',
    to: { name: 'invoices' },
    icon: 'mdi-file-document-outline',
    show: !inSetupMode && showInvoicesItem.value,
    dataTest: 'nav-link-invoices',
    countBadge: invoiceInboxCount.value,
  },
  {
    label: 'Requisitions',
    to: { name: 'requisitions' },
    icon: 'mdi-cart-outline',
    show: !inSetupMode && (isStaff || canUseRequisitions),
    dataTest: 'nav-link-reqsuitions'
  },
  {
    label: 'Projects',
    to: { name: 'projects' },
    icon: 'mdi-format-list-checkbox',
    show: true,
    dataTest: 'nav-link-projects',
  },
  {
    label: 'Users',
    to: { name: 'users' },
    icon: 'mdi-account-outline',
    show: canManageUsers,
    dataTest: 'nav-link-users',
  },
  {
    label: 'Accounting',
    to: { name: 'accounting' },
    icon: 'mdi-refresh',
    show: showAccountingFailureLink.value,
    dataTest: 'nav-link-accounting',
    countBadge: accountingFailuresCount.value,
  },
  {
    label: 'Accounting Export',
    to: { name: 'accounting-export' },
    icon: 'mdi-file-export-outline',
    dataTest: 'nav-link-accounting-export',
    show: isStaff || !!userCompany?.bulk_report_type,
  },
  {
    label: 'Suppliers',
    to: { name: 'suppliers' },
    icon: 'mdi-store-outline',
    show: isStaff,
    dataTest: 'nav-link-suppliers',
  },
  {
    label: 'Company Settings',
    to: { name: 'companies-id', params: { id: Number(userCompany?.id) } },
    icon: 'mdi-cog-outline',
    show: canManageCompany,
    dataTest: 'nav-link-company',
  }
])
const visibleItems = computed(() => items.value.filter(item => item.show))

const { $api, $error } = useNuxtApp()
const refreshAccountingFailures = async () => {
  const failureCounts = await Promise.all([
    $api.v1.purchaseOrders.list({ accounting_success: false, limit: 1 }).then(response => response.count),
    $api.v1.receipts.list({ accounting_success: false, limit: 1 }).then(response => response.count),
    $api.v1.expenses.getAccountingFailuresCount(),
    $api.v1.invoices.list({ accounting_success: false, limit: 1 }).then(response => response.count)
  ])

  accountingFailuresCount.value = failureCounts.reduce((acc, count) => acc + count, 0)
}
const refreshInvoiceCount = () => {
  $api.v1.attachments.list({ category: 4, limit: 1, unassociated: true })
    .then(response => { invoiceInboxCount.value = response.count })
    .catch($error.report)
}
let refreshInvoiceCountTimer: ReturnType<typeof setInterval>
const setShowInvoices = () => {
  if (canManageInvoices) {
    showInvoicesItem.value = true
    refreshInvoiceCount()
    refreshInvoiceCountTimer = setInterval(refreshInvoiceCount, REFRESH_INTERVAL)
  }
  else {
    $api.v1.jobsites.list({ authority: user?.id, invoices: true, limit: 1 } as any)
      .then(response => { showInvoicesItem.value = response.data.length > 0 })
      .catch($error.report)
  }
}

let accountingFailuresTimer: ReturnType<typeof setInterval>
onMounted(() => {
  if (showAccountingFailureLink.value) {
    refreshAccountingFailures()
    accountingFailuresTimer = setInterval(refreshAccountingFailures, REFRESH_INTERVAL)
  }
  setShowInvoices()
})
onBeforeUnmount(() => {
  clearInterval(accountingFailuresTimer)
  clearInterval(refreshInvoiceCountTimer)
})
</script>
