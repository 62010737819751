<template>
  <v-menu location="bottom" content-class="qtm-border">
    <template v-slot:activator="{ props }">
      <qtm-btn
        v-bind="props"
        tertiary
      >
        <v-icon class="mr-2">
          mdi-help-circle-outline
        </v-icon>
        Help
      </qtm-btn>
    </template>
    <v-list>
      <v-list-item @click="toggleFreshChat">
        <template v-slot:prepend>
          <v-icon>
            mdi-forum
          </v-icon>
        </template>
        <v-list-item-title>
          Chat With Us
        </v-list-item-title>
      </v-list-item>
      <v-list-item href="https://quotetome.freshdesk.com/support/home" target="_blank">
        <template v-slot:prepend>
          <v-icon>
            mdi-book-open-variant
          </v-icon>
        </template>
        <v-list-item-title>
          Visit Help Center
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
const authStore = useAuthStore()
const user = authStore.user!

const freshChatOpen = ref(false)

const launchFreshChat = () => initialize(document, 'freshchat-js-sdk')
const toggleFreshChat = () => {
  if (freshChatOpen.value) {
    (window as any).fcWidget?.close()
  }
  else {
    (window as any).fcWidget?.open()
  }
}

const config = useRuntimeConfig()
const initFreshChat = () => {
  (window as any).fcWidget?.init({
    token: config.public.FRESHDESK_API_KEY,
    host: 'https://wchat.freshchat.com',
    tags: ['in_app_support', 'suggestions'],
    externalId: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    phone: user.phone,
    phoneCountryCode: '+1',
    company: user.company,
    company_jobtitle: user.company_jobtitle,
    config: {
      headerProperty: {
        hideChatButton: true,
      },
    },
  });

  (window as any).fcWidget?.on('widget:closed', () => {
    freshChatOpen.value = false
  });

  (window as any).fcWidget?.on('widget:opened', () => {
    freshChatOpen.value = true
  })
}

const initialize = (i: Document, t: string) => { // function by freshchat
  if (i.getElementById(t)) {
    initFreshChat()
  }
  else {
    const e = i.createElement('script')
    e.id = '_1'
    e.async = !0
    e.src = 'https://wchat.freshchat.com/js/widget.js'
    e.onload = initFreshChat
    i.head.appendChild(e)
  }

  window.addEventListener('load', initFreshChat, !1)
}

launchFreshChat()
</script>
