<template>
  <v-menu v-model="showDatePicker" v-bind="menuProps" @update:model-value="emitClosed">
    <template v-slot:activator="{ props: activator }">
      <slot name="activator" :props="activator">
        <qtm-text-field
          v-model="fullDateString"
          v-bind="{ ...$attrs, ...activator }"
          :placeholder="placeholder"
          prepend-inner-icon="mdi-calendar"
          readonly
        />
      </slot>
    </template>
    <slot
      :date-string="dateString"
      :date-value="dateValue"
      :min-date="minDate"
    >
      <v-date-picker
        v-model="dateValue"
        border
        color="interactive"
        hide-header
        :max="maxDate"
        :min="minDate"
        :multiple="multiple"
      />
    </slot>
    <slot name="append" />
  </v-menu>
</template>

<script setup lang="ts">
import { DateTime } from '@quotetome/materials-api'
import { dateFormat } from '~/models/filters'

export interface Props {
  closeOnClick?: boolean
  cutoff?: DateTime | null
  dateStringAppend?: string
  emitDate?: boolean
  formatString?: string
  formatStringAppend?: string
  maxDate?: Date | null
  menuProps?: Record<string, any>
  modelValue?: DateTime | null
  newHour?: number
  newMinute?: number
  placeholder?: string
  isRange?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  cutoff: undefined,
  dateStringAppend: '',
  formatString: dateFormat,
  formatStringAppend: '',
  maxDate: undefined,
  menuProps: () => ({ closeOnContentClick: false, minWidth: 'auto' }),
  modelValue: null,
  newHour: 12,
  newMinute: 0,
  placeholder: 'Select Date',
})
const emit = defineEmits(['closed', 'update:model-value'])

const previousValue = ref(props.modelValue)
const showDatePicker = ref(false)

const dateValue = computed({
  get() {
    return props.modelValue?.toDate?.() ?? (props.modelValue as Date | null)
  },
  set(newDate: Date) {
    if (props.emitDate) {
      emit('update:model-value', newDate)
    }
    else if (newDate) {
      let date = new DateTime(props.modelValue || undefined)
        .withYear(newDate.getFullYear())
        .withMonth(newDate.getMonth())
        .withDay(newDate.getDate())

      if (!previousValue.value) {
        date = date.withHour(props.newHour)
          .withMinute(props.newMinute)
          .withSecond(0)
          .withMillisecond(0)
      }

      emit('update:model-value', date)
      previousValue.value = new DateTime(date)
    }
    else {
      emit('update:model-value', null)
      previousValue.value = null
    }

    if (props.closeOnClick) {
      showDatePicker.value = false
    }
  }
})

const dateString = computed(() => {
  let date = props.modelValue

  if (date && !DateTime.isDateTime(date)) {
    date = new DateTime(date)
  }

  return date?.format(props.formatString) ?? ''
})

const fullDateString = computed({
  get() {
    let date = props.modelValue

    if (date && !DateTime.isDateTime(date)) {
      date = new DateTime(date)
    }

    return (date?.format(props.formatString + props.formatStringAppend) ?? '') + props.dateStringAppend
  },
  // Only set with clearing
  set() {
    emit('update:model-value', null)
    previousValue.value = null
  }
})

const minDate = computed(() => props.cutoff?.toDate() ?? undefined)
const multiple = computed(() => (props.isRange ? 'range' : undefined))

const close = () => {
  showDatePicker.value = false
}

const emitClosed = (value: boolean) => {
  if (!value) {
    emit('closed')
  }
}

const setValue = (value: Date) => {
  dateValue.value = value
}

defineExpose({ close, setValue })
</script>

<style lang="scss">
.v-date-picker-month__day--week- {
  &start,
  &end {
    opacity: 0.5;
  }
}
</style>
