<template>
  <v-card>
    <v-card-title class="px-6">
      <slot name="title-prepend" />
      <div class="d-flex aliugn-center justify-space-between mt-6">
        <div class="text-secondary">
          <quote-awarded-chip v-if="hasPO && !order.requiresAdjustments" :quote="quote" />
          <quote-outdated-chip class="d-inline-block mb-1" :quote="quote" />
          <div class="qtm-h1" v-text="quote.vendor?.name" />
          <div v-if="priceGiven" class="mt-4 qtm-title-bold" v-text="totalPrice" />
        </div>
        <div v-if="showActions">
          <qtm-btn v-if="order.canAwardQuote && priceGiven" secondary @click="previewPO = true">
            <v-icon location="left">
              mdi-text-box-search-outline
            </v-icon>
            Preview PO
          </qtm-btn>
          <qtm-btn
            v-else-if="hasPO && order.requiresAdjustments && !quote.is_winning_quote && priceGiven"
            secondary
            @click="showChangeAwardedQuoteConfirmDialog = true"
          >
            Select this quote instead
          </qtm-btn>

          <qtm-btn
            v-if="canEdit"
            class="px-0"
            density="compact"
            tertiary
            :to="{ name: 'orders-id-quotes-quote', params: { id: order.id, quote: quote.id } }"
          >
            <v-icon class="mr-2">
              mdi-tray-arrow-up
            </v-icon>
            Upload Quote
            <v-tooltip
              activator="parent"
              content-class="bg-mid-dark-grey pa-2 rounded-sm text-center text-white"
              location="top"
              max-width="300"
              opacity="1"
            >
              Upload the quote manually if you received it directly from the supplier.
            </v-tooltip>
          </qtm-btn>
          <quote-items-btn
            v-else-if="priceGiven"
            class="ml-1"
            :order="order"
            :quote="quote"
            @update:order="$emit('update:order', $event)"
          />
        </div>
      </div>
      <preview-po-dialog :id="quote.id" v-model="previewPO" quote />
      <change-awarded-quote-confirm-dialog
        v-if="showChangeAwardedQuoteConfirmDialog"
        v-model="showChangeAwardedQuoteConfirmDialog"
        :quote="quote"
        :order="order"
        :loading="loading"
        @confirm="changeAwardedQuote"
      />
    </v-card-title>

    <v-card-text class="pt-0 px-6">
      <quote-attachments-link v-if="quote.attachments.length" :quote="quote" />
      <quote-delivery-warning class="mt-2" fit :quote="quote" />
      <details-container class="mt-2" :details="details" label-size="9em">
        <template v-slot:delivery>
          <quote-delivery-status :quote="quote" :order="order" />
        </template>
      </details-container>
      <purchase-order-items-table
        v-if="priceGiven"
        class="mt-6"
        :delivery-charge="quote.delivery_charge"
        for-quote
        :items="items"
        :pickup-charge="quote.pickup_charge"
        total-label="Total"
        :total-price="quote.total_price"
      />
      <quote-response
        v-else
        class="bg-background mt-4"
        :response="String(quote.response_received)"
        style="height: 110px"
      />
      <div v-if="quote.comments">
        <div class="mt-4 qtm-h4 text-secondary">
          Supplier Comment
        </div>
        <div v-linkify="quote.comments" />
      </div>
      <v-divider class="my-6" />
      <supplier-chat :order="order" :quote="quote" />
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import type { Order, Quote } from '@quotetome/materials-api'
import ChangeAwardedQuoteConfirmDialog from '@/components/purchase-orders/change-awarded-quote-confirm-dialog.vue'
import DetailsContainer from '@/components/details-container.vue'
import PreviewPoDialog from '@/components/purchase-orders/preview-po-dialog.vue'
import PurchaseOrderItemsTable from '@/components/purchase-orders/purchase-order-items-table.vue'
import QuoteAttachmentsLink from '@/components/quotes/quote-attachments-link.vue'
import QuoteAwardedChip from '@/components/quotes/quote-awarded-chip.vue'
import QuoteDeliveryStatus from '@/components/quotes/quote-delivery-status.vue'
import QuoteDeliveryWarning from '@/components/quotes/quote-delivery-warning.vue'
import QuoteItemsBtn from '@/components/quotes/quote-items-btn.vue'
import QuoteOutdatedChip from '@/components/quotes/quote-outdated-chip.vue'
import QuoteResponse from '@/components/quotes/quote-response.vue'
import SupplierChat from '@/components/vendors/supplier-chat.vue'
import { currency } from '@/models/filters'

export interface Props {
  order: Order
  quote: Quote
  showActions?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:order'])

const loading = ref(false)
const previewPO = ref(false)
const showChangeAwardedQuoteConfirmDialog = ref(false)

const canEdit = computed(() => !props.quote.response_received || props.quote.response_received === 'awaiting price')
const details = computed(() => [
  {
    label: props.order.customer_pickup ? 'Pick-up Date' : 'Delivery Date',
    value: 'delivery',
    include: priceGiven.value
      && (props.order.delivery_required || props.order.customer_pickup)
      && props.quote.delivery_available,
  },
  {
    label: 'Supplier Contact',
    text: props.quote.salesperson
      ? `${props.quote.salesperson.first_name} ${props.quote.salesperson.last_name} (${props.quote.salesperson.email})`
      : '',
    value: 'contact',
    include: true,
  },
  {
    label: 'Supplier Reference',
    text: props.quote.supplier_reference,
    value: 'ref',
    include: props.quote.supplier_reference,
  },
].filter(entry => entry.include))
const hasPO = computed(() => props.order.hasPurchaseOrder)
const items = computed(() => props.quote.skus.map((item, i) => ({ ...item, item_number: i + 1 })))
const priceGiven = computed(() => props.quote.response_received === 'price given')
const totalPrice = computed(() => currency(props.quote.total_price))

const router = useRouter()
const { $api, $error } = useNuxtApp()
const changeAwardedQuote = async () => {
  loading.value = true
  try {
    const order = await $api.v1.rfqs.awardQuote(props.order.id as number, props.quote.id as number)

    emit('update:order', order)
    router.push({ name: 'orders-id-change-order', params: { id: props.order.id } })
  }
  catch (error) {
    $error.report(error)
  }

  showChangeAwardedQuoteConfirmDialog.value = false
  loading.value = false
}
</script>
