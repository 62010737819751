<template>
  <qtm-text-field v-maska="options" v-bind="$attrs" />
</template>

<script setup lang="ts">
import { vMaska } from 'maska/vue'

const MASKS = {
  CA: '@#@ #@#',
  US: '##########',
}

export interface Props {
  country: keyof typeof MASKS
}

const props = defineProps<Props>()

const options = computed(() => ({
  mask: MASKS[props.country],
  postProcess: (value?: string) => value?.toUpperCase() ?? '',
}))
</script>
