import payload_plugin_qLmFnukI99 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import axios_QMFgzss1s4 from "/opt/build/repo/plugins/axios.ts";
import error_handler_kEP5FliEXj from "/opt/build/repo/plugins/error-handler.ts";
import linkify_Jtlh5w3pAW from "/opt/build/repo/plugins/linkify.ts";
import materials_api_65j79Ubv8C from "/opt/build/repo/plugins/materials-api.ts";
import mixpanel_vYbGOFriae from "/opt/build/repo/plugins/mixpanel.ts";
import msal_YjX35aVrLC from "/opt/build/repo/plugins/msal.ts";
import sentry_3AyO8nEfhE from "/opt/build/repo/plugins/sentry.ts";
import toast_ysMjUcU7eJ from "/opt/build/repo/plugins/toast.ts";
import vuetify_7h9QAQEssH from "/opt/build/repo/plugins/vuetify.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  axios_QMFgzss1s4,
  error_handler_kEP5FliEXj,
  linkify_Jtlh5w3pAW,
  materials_api_65j79Ubv8C,
  mixpanel_vYbGOFriae,
  msal_YjX35aVrLC,
  sentry_3AyO8nEfhE,
  toast_ysMjUcU7eJ,
  vuetify_7h9QAQEssH
]