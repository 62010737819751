<template>
  <div class="d-flex">
    <div class="align-stretch d-flex qtm-border">
      <v-autocomplete v-bind="config" v-model="hour" :items="hours" />
      <div class="bg-background pt-2">
        :
      </div>
      <v-autocomplete v-bind="config" v-model="minute" :items="minutes" />
      <v-autocomplete v-bind="config" v-model="amPm" width="65" :items="['AM', 'PM']" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from '@quotetome/materials-api'

export interface Props {
  modelValue?: DateTime | null
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
})
const emit = defineEmits(['update:model-value'])

const config = {
  class: 'pa-none',
  color: 'interactive',
  density: 'compact',
  flat: true,
  hideDetails: true,
  width: 60,
  menuIcon: '',
  padding: 0,
  variant: 'solo-filled',
}
const hours = [...Array(12).keys().map(i => String(i + 1))]
const minutes = ['00', '15', '30', '45']

const amPm = computed({
  get() {
    return (props.modelValue?.toDate()?.getHours() ?? 0) >= 12 ? 'PM' : 'AM'
  },
  set(newAmPm: string) {
    const offset = newAmPm === 'PM' ? 12 : -12
    const date = new DateTime(props.modelValue || undefined)
      .withHour(Number(props.modelValue?.toDate()?.getHours()) + offset)
      .withMinute(Number(minute.value))
      .withSecond(0)
      .withMillisecond(0)

    emit('update:model-value', date)
  },
})

const hour = computed({
  get() {
    const h = props.modelValue?.toDate()?.getHours() || 12

    if (h > 12) {
      return String(h - 12)
    }

    return String(h)
  },
  set(newHour: string) {
    let h = Number(newHour)

    if (h === 12) {
      h = 0
    }

    const offset = amPm.value === 'PM' ? 12 : 0
    const date = new DateTime(props.modelValue || undefined)
      .withHour(h + offset)
      .withMinute(Number(minute.value))
      .withSecond(0)
      .withMillisecond(0)

    emit('update:model-value', date)
  },
})

const minute = computed({
  get() {
    return String(props.modelValue?.toDate()?.getMinutes() ?? minutes[0]).padStart(2, '0')
  },
  set(newMinute: string) {
    const date = new DateTime(props.modelValue || undefined)
      .withHour(props.modelValue?.toDate()?.getHours() ?? 0)
      .withMinute(Number(newMinute))
      .withSecond(0)
      .withMillisecond(0)

    emit('update:model-value', date)
  }
})
</script>
