export default function useSearch(api: (params: any) => any, { limit = 20 } = {}) {
  const { $error } = useNuxtApp()
  const loading = ref(false)
  const results = ref<any[]>([])
  let offset = 0
  const internalSearch = async (query?: string, params?: any) => {
    const cleanQuery = query?.trim().toLowerCase()

    if (cleanQuery) {
      const pagination = { limit, offset }

      loading.value = true
      try {
        const response = await api({
          pagination,
          search: cleanQuery,
          ...pagination,
          ...params,
        })

        offset += limit

        let newResults = []

        if (Array.isArray(response)) {
          newResults = response
        }
        else {
          newResults = response.data
        }

        results.value = results.value.concat(newResults)
      }
      catch (error) {
        $error.report(error as any)
      }
      loading.value = false
    }
  }
  const search = (query: string, params?: any) => {
    results.value = []
    offset = 0
    return internalSearch(query, params)
  }

  return {
    loading,
    results,
    search,
  }
}
